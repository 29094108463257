/*
  A generic type-ahead component that allows fetching search results from
  the server (as html) and inserting them into the DOM.

  Search requests are throttled to avoid overloading the server

  This component is a simple wrapper around a search input and a content container.

  ```
    <drb-type-ahead query-path="/search">
      <input type="search" placeholder="Search...">

      <div data-type-ahead-content>
        Default content
        <!-- Results will be rendered here, replacing any default content -->
      </div>
    </drb-type-ahead>
  ```

  The component expects there to be an input element and a content element with the
  `[data-type-ahead-content]` attribute.

  Attributes:
    - `query-path` (string) - the path to fetch the search results from.
    - `query-key` (string) - the query parameter key to use when sending the search query. (default: "q")
    - `external` (boolean) - if present, the search request will not include the `X-Requested-With: XMLHttpRequest` header (which is what Rails uses to detect AJAX requests). When working with an external API, this header can sometimes cause the requests to be rejected. Setting this to true, will remove the header. (default: false)
*/

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import throttle from 'lodash/throttle';

@customElement('drb-type-ahead')
class DrbTypeAhead extends LitElement {
  input: HTMLInputElement | null = this.querySelector('input');
  contentEl: HTMLElement | null = this.querySelector('[data-type-ahead-content]');
  transformContent?: (content: any) => string;
  private _initialContentHtml: string = this.contentEl?.innerHTML || '';
  private _searchAbortController = new AbortController();
  private _throttledSearch = throttle(this._search, 300, { leading: false });

  @property({ attribute: 'query-path', type: String })
  queryPath = '';

  @property({ attribute: 'query-key', type: String })
  queryKey = 'q';

  @property({ type: Boolean })
  external = false;

  @state()
  fetching = false;

  @state()
  shouldReFetch = false;

  connectedCallback() {
    super.connectedCallback();
    this.initialize();

  }

  initialize() {
    if (!this.input || !this.contentEl) return;

    this.input.addEventListener('input', () => {
      if (this.input.value) {
        this._throttledSearch();
      }
      else {
        this.reset();
      }
    });
  }

  private _setContent(html: string) {
    // skip if the content is unchanged
    if (this.contentEl.innerHTML === html) return;

    // set the content
    this.contentEl.innerHTML = html;

    // dispatch event
    this.dispatchEvent(new CustomEvent('drb-type-ahead-updated', {
      bubbles: true,
      composed: true
    }));
  }

  private async _search() {
    if (this.fetching) {
      this.shouldReFetch = true;
      return;
    }

    this.fetching = true;

    // build query url
    const queryUrl = this.queryPath.startsWith('/')
      ? new URL(window.location.origin + this.queryPath)
      : new URL(this.queryPath);

    queryUrl.searchParams.set(this.queryKey, this.input.value);

    // fetch search results
    try {
      const response = await fetch(queryUrl.toString(), {
        method: 'GET',
        headers: this.external ? {} : {
          "X-Requested-With": "XMLHttpRequest",
        },
        signal: this._searchAbortController.signal
      })

      if (!response.ok) throw new Error('Something went wrong');

      const isJson = response.headers.get("content-type") === "application/json";
      const responseContent = isJson ? await response.json() : await response.text();
      this._setContent(this.transformContent?.(responseContent) || responseContent);
    } catch (error) {
      console.warn('Search error:', error);
    } finally {
      this.fetching = false;

      // in case a new throttled search was triggered while the previous one
      // was still fetching, we can re-fetch immediately to keep the UX snappy
      if (this.shouldReFetch) {
        this.shouldReFetch = false;
        this._search();
      }
    }
  }

  reset() {
    // clear input
    this.input.value = '';

    // cancel any pending search request & scheduled throttled callback
    this._throttledSearch.cancel();
    this._searchAbortController.abort();
    this._searchAbortController = new AbortController();

    // reset fetching state
    this.fetching = false;
    this.shouldReFetch = false;

    // reset content
    this._setContent(this._initialContentHtml);
  }

  render() {
    return html`
      <slot></slot>
    `;
  }
}

export { DrbTypeAhead };
