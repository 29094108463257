import { PristineValidation } from '~/globals/form-validation/form-validation';
import { InternalTracking } from '~/shared/modules/internal-tracking';

export const MessageDialog = {
  // global listeners for message dialog related actions
  bindListeners() {
    this.bindMessageInputSync();
    this.bindFormValidation();
    this.bindTrackingListeners();
  },

  bindMessageInputSync() {
    document.addEventListener('input', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      const messageInputs = messageDialog.querySelectorAll('#message-dialog-message, #message-dialog-details');
      if (!Array.from(messageInputs).includes(e.target)) return;

      messageInputs.forEach((input) => {
        input.value = e.target.value;
      });
    });
  },

  bindFormValidation() {
    // bind form validation to message dialog forms when they are loaded
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      messageDialog.querySelectorAll('[data-message-dialog-form]').forEach((form) => {
        form.pristineValidation = PristineValidation(form);
        this.bindBudgetFieldLogic(form);
      });
    });

    // validate the form on submit
    document.addEventListener('submit', (e) => {
      const form = e.target.closest('[data-message-dialog-form]');
      if (!form) return;

      e.preventDefault();

      // submit the form if valid and not already submitting
      if (!form.hasAttribute('is-submitting') && form.pristineValidation?.validate()) {
        this.submitForm(form);
      }
    });
  },

  bindBudgetFieldLogic(form) {
    const budgetField = form.querySelector('#message-dialog-budget');
    const budgetNotSureCheckbox = form.querySelector('#message-dialog-budget-not-sure');
    if (!budgetField || !budgetNotSureCheckbox) return;

    // custom validator that makes budget required when 'not sure' checkbox is unchecked
    form.pristineValidation.addValidator(budgetField, (value) => {
      if (budgetNotSureCheckbox.checked) return true;
      return !!value?.length;
    }, 'Please enter your budget.', 2, false);

    // toggle budget field state based on 'not sure' checkbox
    budgetNotSureCheckbox.addEventListener('change', () => {
      budgetField.toggleAttribute('disabled', budgetNotSureCheckbox.checked);
      budgetField.value = '';

      if (budgetNotSureCheckbox.checked) {
        form.pristineValidation?.validate(budgetField);
      }

      // reset validation state for budget field to prevent immediate errors when typing
      if (budgetField.pristine) {
        budgetField.pristine.hasValidated = false;
      }
    });
  },

  bindTrackingListeners() {
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      const form = messageDialog.querySelector('[data-message-dialog-form]');
      const userId = form?.querySelector("input[name='engagement[recipient_id]']")?.value;
      if (!userId) return;

      InternalTracking.trackSearchMessageModalDisplayed(userId, messageDialog);
    });
  },

  async submitForm(form) {
    const defaultServerErrorMsg = 'Failed to send message';
    const submitBtn = form.querySelector('[data-message-dialog-submit]');
    const formError = form.querySelector('[data-form-error]');

    // disable the submit button and show loading state
    const toggleFormSubmitting = (isSubmitting) => {
      form.toggleAttribute('is-submitting', isSubmitting);
      submitBtn.disabled = isSubmitting;
      submitBtn.classList.toggle('btn2--loading', isSubmitting);
    };

    // incase of server error, show error message
    const setServerErrorMsg = (errorMsg) => {
      if (!formError) return;
      formError.toggleAttribute('hidden', !errorMsg);
      formError.innerText = errorMsg;
    };

    // reset state
    toggleFormSubmitting(true);
    setServerErrorMsg(null);

    try {
      const response = await fetch(form.getAttribute('action'), {
        method: 'POST',
        body: this.getTransformedFormData(form),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      const responseText = await response.text();

      if (!response.ok) {
        // use error message provided by BE if available (422), otherwise use default
        const errorMsg = response.status === 422 ? responseText : defaultServerErrorMsg;
        throw new Error(errorMsg || defaultServerErrorMsg);
      }

      // update the dialog content with the response (e.g. success message, etc.)
      const dialog = form.closest('drb-dialog');
      if (!dialog) return;

      dialog.setContent(responseText);
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : defaultServerErrorMsg;
      setServerErrorMsg(errorMsg);
      console.error(errorMsg, error);
    } finally {
      toggleFormSubmitting(false);
    }
  },

  getTransformedFormData(form) {
    const formData = new FormData(form);

    switch (form.getAttribute('data-message-dialog-form')) {
      case 'workRequest':
        formData.set('engagement[budget_cents]', parseInt(formData.get('budget_dollars') || 0, 10) * 100);
        formData.delete('budget_dollars');
        break;

      default:
        break;
    }

    return formData;
  },

};
