export const scrollIntoViewIfNeeded = (element, offset = 0) => {
  if (!element) return;
  const rect = element.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const isInViewPort = rect.top >= offset && (rect.top + offset) <= windowHeight;

  if (!isInViewPort) {
    window.scrollTo({
      top: window.scrollY + rect.top - offset,
      behavior: 'smooth',
    });
  }
};
