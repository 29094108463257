import { SearchUUID } from '~/shared/utils/search-uuid';

export const InternalTracking = {
  getCSRFToken() {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    return metaTag?.getAttribute('content') || '';
  },

  trackAdClicked(payload) {
    return this.trackInternally('AdClicked', payload);
  },

  trackAdRequestFailed(payload) {
    return this.trackInternally('AdRequestFailed', payload);
  },

  trackAdRequested(payload) {
    return this.trackInternally('AdRequested', payload);
  },

  trackAdServed(payload) {
    return this.trackInternally('AdServed', payload);
  },

  trackAdExpanded(payload) {
    return this.trackInternally('AdExpanded', payload);
  },

  trackAdCollapsed(payload) {
    return this.trackInternally('AdCollapsed', payload);
  },

  trackAdDismissed(payload) {
    return this.trackInternally('AdDismissed', payload);
  },

  trackCheckoutViewed(payload) {
    return this.trackInternally('CheckoutViewed', payload);
  },

  trackCTAClicked(payload) {
    return this.trackInternally('CTAClicked', payload);
  },

  trackInternally(eventName, payload) {
    // default properties included with every event
    const defaultProperties = {
      page_url: window.location.href,
    };

    return fetch(
      '/client_app/events',
      {
        body: JSON.stringify({
          event: {
            name: eventName,
            payload: {
              ...defaultProperties,
              ...payload,
            },
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.getCSRFToken(),
        },
        method: 'POST',
      },
    );
  },

  bindGlobalListeners() {
    const ctaClicked = (e) => {
      const trigger = e.target?.closest('[data-internal-track-cta]');
      if (!trigger) return;

      const payload = {
        subject_id: trigger.getAttribute('data-internal-subject-id') || '',
        subject_type: trigger.getAttribute('data-internal-subject-type') || '',
        name: trigger.getAttribute('data-internal-track-cta') || trigger.textContent.trim(),
      };

      this.trackCTAClicked(payload);
    };

    document.addEventListener('click', (e) => {
      ctaClicked(e);
    });
  },

  bindGlobalSearchActivityListeners() {
    const messageBtnClicked = (e) => {
      const legacyMessageBtn = e.target.closest('.js-contact-overlay-trigger');
      const messageBtn = e.target.closest('[data-message-dialog-button]');

      const isMessageBtn = !!legacyMessageBtn || !!messageBtn;
      if (!isMessageBtn) return;

      const userID = e.target.closest('[data-message-recipient]')?.getAttribute('data-message-recipient');
      if (!userID) return;

      this.trackSearchMessageCTAClicked(userID, e.target);
    };

    const profileClicked = (e) => {
      const userID = e.target.closest('[data-search-profile-clicked]')?.getAttribute('data-search-profile-clicked');
      if (!userID) return;

      this.trackSearchProfileClicked(userID, e.target);
    };

    const shotClicked = (e) => {
      const shotID = e.target.closest('[data-shot-thumbnail-link]')?.getAttribute('data-shot-thumbnail-link');
      if (!shotID) return;

      this.trackSearchShotClicked(shotID, e.target);
    };

    // Click based events
    document.addEventListener('click', (e) => {
      messageBtnClicked(e);
      profileClicked(e);
      shotClicked(e);
    }, true);

    // ShotSeen
    Dribbble.EventBus.$on('shotViewRecorder:shotsSeen', (shotIDs) => {
      if (!shotIDs || shotIDs.length === 0) return;

      const shotsGroupedByUUID = Array.from(shotIDs).reduce((acc, shotId) => {
        const thumbnailEl = document.querySelector(`[data-shot-thumbnail-link="${shotId}"]`);
        if (!thumbnailEl) return acc;

        const groupKey = SearchUUID.get(thumbnailEl);
        if (!groupKey) return acc;

        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }

        acc[groupKey].push(shotId);
        return acc;
      }, {});

      // track each group of shots viewed with a distinct UUID separately
      Object.entries(shotsGroupedByUUID).forEach(([uuid, shotIds]) => {
        this.trackSearchShotsSeen(shotIds, uuid);
      });
    });
  },

  trackSearchUserMessaged(userID, contextElement = null) {
    return this.trackSearchActivity({
      target: userID,
      target_type: 'User',
      name: 'UserMessaged',
    }, contextElement);
  },

  trackSearchMessageCTAClicked(userID, contextElement = null) {
    return this.trackSearchActivity({
      target: userID,
      target_type: 'User',
      name: 'MessageCTAClicked',
    }, contextElement);
  },

  trackSearchMessageModalDisplayed(userID, contextElement = null) {
    return this.trackSearchActivity({
      target: userID,
      target_type: 'User',
      name: 'MessageModalDisplayed',
    }, contextElement);
  },

  trackSearchShotClicked(shotID, contextElement = null) {
    return this.trackSearchActivity({
      target: shotID,
      target_type: 'Screenshot',
      name: 'ShotClicked',
    }, contextElement);
  },

  trackSearchShotLiked(shotID, contextElement = null) {
    return this.trackSearchActivity({
      target: shotID,
      target_type: 'Screenshot',
      name: 'ShotLiked',
    }, contextElement);
  },

  trackSearchShotSaved(shotID, contextElement = null) {
    return this.trackSearchActivity({
      target: shotID,
      target_type: 'Screenshot',
      name: 'ShotSaved',
    }, contextElement);
  },

  trackSearchUserFollowed(userId, contextElement = null) {
    return this.trackSearchActivity({
      target: userId,
      target_type: 'User',
      name: 'UserFollowed',
    }, contextElement);
  },

  trackSearchShotsSeen(shotIDs, uuid) {
    if (!shotIDs || shotIDs.length === 0 || !uuid) return null;

    const shotViewedEvents = shotIDs.map((shotID) => ({
      uuid,
      target: shotID,
      target_type: 'Screenshot',
      name: 'ShotSeen',
    }));

    return this.trackSearchActivity(shotViewedEvents);
  },

  trackSearchProfileClicked(userID, contextElement = null) {
    return this.trackSearchActivity({
      target: userID,
      target_type: 'User',
      name: 'ProfileClicked',
    }, contextElement);
  },

  trackSearchDesignerBookmarked(userID, contextElement = null) {
    return this.trackSearchActivity({
      target: userID,
      target_type: 'User',
      name: 'DesignerBookmarked',
    }, contextElement);
  },

  trackSearchActivity(eventData, contextElement = null) {
    if (!eventData) return;

    const eventsArray = Array.isArray(eventData) ? eventData : [eventData];

    // transform and validate each event payload
    const validatedEventsArray = eventsArray.reduce((acc, eventPayload) => {
      if (!eventPayload.uuid) eventPayload.uuid = SearchUUID.get(contextElement);
      if (!eventPayload.uuid) return acc;

      const requiredProperties = ['uuid', 'target', 'target_type', 'name'];
      const payloadKeys = Object.keys(eventPayload);
      const isValidPayload = payloadKeys.length === requiredProperties.length && payloadKeys.every((key) => requiredProperties.includes(key));

      if (isValidPayload) acc.push(eventPayload);
      return acc;
    }, []);

    if (validatedEventsArray.length === 0) return;

    // eslint-disable-next-line consistent-return
    return fetch(
      '/client_app/search_results',
      {
        body: JSON.stringify({
          events: validatedEventsArray,
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.getCSRFToken(),
        },
        method: 'POST',
      },
    );
  },
};
